import React, { useState } from "react";
import ScrollToTop from "../components/ScrollToTop";
import Sidebar from "../components/Sidebar";
import NavbarProject from "../components/NavbarProject";
import Projects from "../components/Projects";
import Footer from "../components/Footer";

const ProjectsPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavbarProject toggle={toggle} />
      <Projects />
      <Footer />
    </>
  );
};

export default ProjectsPage;
