import styled from "styled-components";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

export const TestimonialContainer = styled.div`
  color: #fff;
  background: #222427;
  max-width: 1250px;

  @media screen and (max-width: 800px) {
    padding: 100px 0;
  }
`;

export const TestimonyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 350px;
  height: 700px;

  @media screen and (max-width: 375px) {
    width: 280px;
    height: auto;
  }
`;

export const Slider = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CarouselWrapper = styled.div`
  display: flex;
  height: 500px;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 24px;
  justify-content: center;
  align-items: flex-start;
`;

export const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LeftArrow = styled(FaArrowAltCircleLeft)`
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #97a9d7;
  cursor: pointer;
  user-select: none;
`;

export const RightArrow = styled(FaArrowAltCircleRight)`
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #97a9d7;
  cursor: pointer;
  user-select: none;
`;

export const PhotoDiv = styled.div`
  height: 200px !important;
  width: 350px !important;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const Photo = styled.img`
  border-radius: 5%;
  max-width: 350px !important;
  max-height: 200px !important;
  display: block;
  width: auto;
  height: 100%;
  aspect-ratio: attr(width) / attr(height);
`;

export const Name = styled.h2`
  font-size: 1.2rem;
  color: #81939e;
  margin: 1rem 0;
  text-align: center;
`;

export const Testimony = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: justify;
`;
