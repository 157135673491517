import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { BtnLink, BtnLink2 } from "../ProjectItem/ProjectItemElements";
import {
  TestimonialWrapper,
  TextWrapper,
  Name,
  EditButton,
  DeleteButton,
} from "./TestimonialItemElements";

const TestimonialItem = ({ signature, id, onDelete }) => {
  const [token, setToken] = useState();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  function remove(id) {
    api
      .delete(`api/testimonials/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        onDelete(id);
      })
      .catch((err) => {}, []);
  }

  const testimonialId = (id) => {
    localStorage.setItem("id", id);
  };

  return (
    <TestimonialWrapper>
      <TextWrapper>
        <Name>{signature}</Name>
      </TextWrapper>
      <BtnLink to="/editTestimonial">
        <EditButton onClick={() => testimonialId(id)} />
      </BtnLink>
      <BtnLink2>
        <DeleteButton
          onClick={() => {
            if (window.confirm("Tem certeza que quer deletar esse Depoimento?"))
              remove(id);
          }}
        />
      </BtnLink2>
    </TestimonialWrapper>
  );
};

export default TestimonialItem;
