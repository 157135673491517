import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { useHistory } from "react-router-dom";

import AdminNavbar from "../../components/AdminNavbar";
import Footer from "../../components/Footer";
import SidebarAdmin from "../../components/SidebarAdmin";

import {
  EditContainer,
  EditWrapper,
  Column,
  TextWrapper,
  Topline,
} from "../adminEditProject/adminEditElements";

import {
  Label,
  Input,
  File,
  FormGroup,
  FormWrapper,
  Button,
  Form,
} from "../../components/TestimonialForm/TestimonialFormElements";

const AdminEditTestimonial = () => {
  const id = localStorage.getItem("id");
  const testimonial_id = id;

  const [isOpen, setIsOpen] = useState(false);
  const [baseImage, setBaseImage] = useState("");
  const [data, setData] = useState({
    signature: "",
    english_signature: "",
    text: "",
    english_text: "",
    photo: "",
    user_id: "1",
  });

  const token = localStorage.getItem("token");

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  async function submit(e) {
    e.preventDefault();
    try{
      await api
        .put(
          `api/testimonials/${id}`,
          {
            signature: data.signature,
            english_signature: data.english_signature,
            text: data.text,
            english_text: data.english_text,
            photo: baseImage.split(",")[1],
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          window.location = "/adminTestimonials";
        });
    } catch(error){
      console.log(error);
    }
  }

  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    api.get(`api/testimonials/${id}`).then((res) => {
      setData(res.data.testimonial);
    });
  }, []);

  // useEffect(() => {
  //   document.getElementById("signature").value = data.signature;
  //   document.getElementById("english_signature").value = data.english_signature;
  //   document.getElementById("text").value = data.text;
  //   document.getElementById("english_text").value = data.english_text;
  // }, [data]);

  return (
    <>
      <SidebarAdmin isOpen={isOpen} toggle={toggle} />
      <AdminNavbar toggle={toggle} />
      <EditContainer>
        <EditWrapper>
          <Column>
            <TextWrapper>
              <Topline>Editar Depoimento</Topline>
            </TextWrapper>

            <Form onSubmit={(e) => submit(e)}>
              <FormWrapper>
                <FormGroup>
                  <Label>Nome</Label>
                  <Input
                    rows="1"
                    type="text"
                    name="signature"
                    onChange={(e) => handle(e)}
                    id="signature"
                    value={data.signature}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Nome (Inglês)</Label>
                  <Input
                    rows="1"
                    type="text"
                    name="english_signature"
                    onChange={(e) => handle(e)}
                    id="english_signature"
                    value={data.english_signature}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Depoimento</Label>
                  <Input
                    rows="2"
                    type="text"
                    name="text"
                    onChange={(e) => handle(e)}
                    id="text"
                    value={data.text}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Depoimento (Inglês)</Label>
                  <Input
                    rows="2"
                    type="text"
                    name="english_text"
                    onChange={(e) => handle(e)}
                    id="english_text"
                    value={data.english_text}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Imagem do Depoimento</Label>
                  <File
                    type="file"
                    name="photo"
                    id="photo"
                    accept=".png"
                    onChange={(e) => {
                      uploadImage(e);
                      handle(e);
                    }}
                    value={data.photo}
                  />
                </FormGroup>
              </FormWrapper>
              <div>
                {baseImage === "" ? (
                  <></>
                ) : (
                  <>
                    <img
                      src={baseImage}
                      height="200px"
                      alt="Imagem selecionada para upload"
                    />
                  </>
                )}
              </div>
              <Button
                type="submit"
                primary="true"
                dark={true}
                duration={500}
                spy={true}
              >
                Atualizar Depoimento
              </Button>
            </Form>
          </Column>
        </EditWrapper>
      </EditContainer>
      <Footer />
    </>
  );
};

export default AdminEditTestimonial;
