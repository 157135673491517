import styled from "styled-components";

export const Button = styled.button`
  .btn-link {
    color: #fff;
  }

  color: #fff;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  list-style: none;
`;
