import React, { useState, useEffect } from "react";
import api from "../../services/api";

import {
  Label,
  Input,
  FormGroup,
  FormWrapper,
  Button,
  Form,
  AdminWrapper,
  AdminContainer,
  Topline,
} from "./SuperAdminElements";

const SuperAdminForm = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
    password2: "",
    is_admin: 1,
  });

  const [token, setToken] = useState();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  function submit(e) {
    e.preventDefault();
    api
      .post(
        `api/users`,
        {
          email: data.email,
          password: data.password,
          password2: data.password2,
          is_admin: data.is_admin,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        window.location.reload();
      });
  }

  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  return (
    <AdminContainer>
      <AdminWrapper>
        <Topline>Criar um novo usuário</Topline>
        <Form onSubmit={(e) => submit(e)}>
          <FormWrapper>
            <FormGroup>
              <Label>Email</Label>
              <Input
                id="email"
                type="text"
                name="signature"
                // placeholder="Insira o endereço de email"
                value={data.email}
                onChange={(e) => handle(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Senha</Label>
              <Input
                id="password"
                type="password"
                name="password"
                // placeholder="Digite sua senha"
                value={data.password}
                onChange={(e) => handle(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Confirmação de senha</Label>
              <Input
                id="password2"
                type="password"
                name="password2"
                // placeholder="Digite a senha novamente"
                value={data.password2}
                onChange={(e) => handle(e)}
              />
            </FormGroup>
          </FormWrapper>
          <Button
            type="submit"
            primary="true"
            dark={true}
            duration={500}
            spy={true}
          >
            Criar
          </Button>
        </Form>
      </AdminWrapper>
    </AdminContainer>
  );
};

export default SuperAdminForm;
