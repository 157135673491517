import React, { useState, useEffect, useRef  } from "react";
import ConsultingSection from "../components/ConsultingSection";
import ContactSection from "../components/ContactSection";
import ProjectSection from "../components/ProjectSection";
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree,
  homeObjFour,
} from "../components/Data";
import HeroSection from "../components/HeroSection";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import TestimonialSection from "../components/TestimonialSection";
import Footer from "../components/Footer";

const Home = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() =>{
    try{
      if(props.location.state.div){
        var onde = document.getElementById(props.location.state.div);
        var conde = onde.getBoundingClientRect();
        var dadada = window.pageYOffset;
        var y = conde.top - 80;
        setTimeout(function(){
           try {
             window.scrollBy(0,y);
             props.location.div =null;
           }
           catch(err){console.log(err);}
         }, 100);
      }
    }
    catch(err){
    }
    // aa();
  },[]);


  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <ConsultingSection {...homeObjOne} />
      <ContactSection {...homeObjTwo} />
      <ProjectSection {...homeObjThree} />
      <TestimonialSection {...homeObjFour} />
      <Footer />
    </>
  );
};

export default Home;
