import React from "react";
import { useTranslation } from "react-i18next";
import TestimonialCarousel from "../TestimonialCarousel";

import {
  Container,
  TextWrapper,
  Wrapper,
  Column,
  Row,
  TopLine,
  Heading,
  Subtitle,
  ImgWrap,
  Img,
} from "../GeneralElements/GeneralElements";

const TestimonialSection = ({ lightBg, id, lightText, darkText, img, alt }) => {
  const { t } = useTranslation();

  return (
    <>
      <Container lightBg={lightBg} id={id}>
        <Wrapper>
          <Column className="testimonials">
            <Row className="firstRow">
              <TextWrapper>
                <TopLine>{t("testimonals_topline")}</TopLine>
                <Heading lightText={lightText}>
                  {t("testimonals_headline")}
                </Heading>
                <Subtitle darkText={darkText}>
                  {t("testimonals_description")}
                </Subtitle>
              </TextWrapper>
            </Row>
            <Row className="lastRowMobile">
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Row>
          </Column>
        </Wrapper>
        <TestimonialCarousel />
      </Container>
    </>
  );
};

export default TestimonialSection;
