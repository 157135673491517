import styled from "styled-components";

export const Container = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#222427" : "#181C21")};
  padding: 0px 100px 0px 100px;

  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1250px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;

  .contactRow {
    justify-content: center;
    margin: 60px 0;
  }

  .projects {
    flex-direction: row;
    margin: 60px 0;
  }

  .testimonials {
    flex-direction: row;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    height: auto;

    .projects {
      flex-direction: column;
      margin: 60px 0;
    }
  }

  @media screen and (max-width: 480px) {
    width: 70%;
    height: auto;

    .projects {
      flex-direction: column;
      margin: 60px 0;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .firstRow {
    margin-top: 60px;
  }

  .lastRow {
    margin-bottom: 60px;
  }

  .noMarginRow {
    margin: 0;
  }

  @media screen and (max-width: 800px) {
    .secondRow {
      margin-top: 45px;
    }

    .lastRowMobile {
      display: none;
    }
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  width: 45%;

  @media screen and (max-width: 800px) {
    width: 100%;
    text-align: center;
  }
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  width: 45%;
  text-align: end;

  @media screen and (max-width: 800px) {
    width: 100%;
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  width: 100%;

  .list2 {
    align-self: flex-end;
  }

  @media screen and (max-width: 800px) {
    width: auto;
  }
`;

export const TopLine = styled.p`
  color: #97a9d7;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h2`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#181C21")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin: 0 0 35px 16px;
  font-size: 18px;
  line-height: 32px;
  color: ${({ darkText }) => (darkText ? "#81939E" : "#181C21")};
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  visibility: hidden;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
