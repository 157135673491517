import React, { useState } from "react";
import logobonus from "../../images/logo.svg";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

import {
  LoginContainer,
  LoginWrapper,
  Label,
  Input,
  Button,
  FormLogin,
  Topline,
  FormGroup,
  FormGroup2,
  FormWrap,
  FormWrapper,
  ButtonWrap,
  LoginWrap,
  Image,
  NewPassword,
} from "./LoginElements";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  async function handleLogin(e) {
    e.preventDefault();

    const data = {
      email,
      password,
    };
    try {
      await api.post("api/login", data).then(res =>{
        alert("Login realizado com sucesso");
        localStorage.setItem("email", res.data.user.email);
        localStorage.setItem("token", res.data.token);

        history.push("/adminTestimonials");
      });
    } catch (err) {
      alert("Falha no login, tente novamente.");
    }
  }

  return (
    <>
      <LoginContainer>
        <LoginWrapper>
          <LoginWrap>
            <Image src={logobonus} alt="Logo Bonus Consultoria" />
            <Topline>Área de administrador</Topline>
            <FormLogin onSubmit={handleLogin}>
              <FormWrapper>
                <FormWrap>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Digite seu email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup2>
                    <Label>Senha</Label>
                    <Input
                      type="password"
                      name="password"
                      placeholder="Digite sua senha"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <NewPassword to="/emailRecovery">
                      Esqueceu sua senha?
                    </NewPassword>
                  </FormGroup2>
                </FormWrap>
              </FormWrapper>
              <ButtonWrap>
                <Button type="submit">Entrar</Button>
              </ButtonWrap>
            </FormLogin>
          </LoginWrap>
        </LoginWrapper>
      </LoginContainer>
    </>
  );
}
