import styled from "styled-components";

export const ProjectContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#222427" : "#181C21")};

  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
`;

export const ProjectWrapper = styled.div`
  display: grid;
  z-index: 1;
  // height: 300px;
  width: 100%;
  max-width: 1250px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const ProjectRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 800px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin: 15px 0;
  padding: 40px 20px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin: 15px 0;
  padding: 40px 20px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: #97a9d7;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h2`
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#181C21")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#81939E" : "#181C21")};
`;

export const MidiaWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const Img = styled.img`
  width: 560px;
  height: 315px;
  margin: 10px 0 10px 0;
  padding-right: 0;
  max-width: 560px !important;
  max-height: 315px !important;
  aspect-ratio: attr(width) / attr(height);

  @media screen and (max-width: 800px) {
  }
`;

export const Iframe = styled.iframe`
  @media screen and (max-width: 800px) {
    width: 560px;
    height: 315px;
  }

  @media screen and (max-width: 480px) {
    width: 420px;
    height: 220px;
  }

  @media screen and (max-width: 414px) {
    width: 340px;
    height: 200px;
  }

  @media screen and (max-width: 375px) {
    width: 315px;
    height: 200px;
  }

  @media screen and (max-width: 360px) {
    width: 280px;
    height: 200px;
  }

  @media screen and (max-width: 320px) {
    width: 240px;
    height: 200px;
  }
`;
