import styled from "styled-components";

export const LoginContainer = styled.div`
  background: #181c21;
`;

export const LoginWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: 100vh;
  width: 100%;
  max-width: 1250px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const LoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
`;

export const Topline = styled.p`
  color: #97a9d7;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const FormLogin = styled.form`
  align-items: center;
`;
export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 50px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const FormGroup2 = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const FormWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Label = styled.label`
  font-size: 15px;
  margin-bottom: 5px;
  color: #81939e;
  display: block;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

export const Input = styled.input`
  padding: 12px;
  color: black;
  background: #ededed;
  border: none;
  height: 40px;
  width: 300px;
  margin: 3% 0;
  resize: none;
`;

export const Button = styled.button`
  width: 100px;
  height: 40px;
  background: #9da1d0;
  margin-top: 10px;
  align-items: center;

  @media screen and (max-width: 480px) {
    width: 100px;
    height: 50px;
  }
`;

export const Image = styled.img`
  height: 150px;

  @media screen and (max-width: 480px) {
    height: 100px;
  }
`;
