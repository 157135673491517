export const homeObjOne = {
  id: "consulting",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Nossos Serviços",
  headline: "Consultoria",
  description:
    "Temos como meta fornecer serviços e consultoria com excelência, visando atender as necessidades de nossos clientes e gerar sempre um BONUS com os nossos serviços:",
  service1:
    "Certificação e Legalização dos Pescadores em parceria com a Marinha do Brasil",
  service2: "Desenvolvimento de projetos especiais",
  service3: "Capacitar os pescadores e fornecedores em:",
  subService1: "Saúde,",
  subService2: "Meio Ambiente,",
  subService3: "Segurança e",
  subService4: "Legislações Marítimas",
  service3End: "visando atender as normas de QSMS do Cliente",
  imgStart: false,
  img: require("../images/svg-1.svg").default,
  triangle1: require("../images/triangle1.jpg").default,
  alt: "MUDAR ALT AQUI",
  dark: true,
  primary: true,
  darkText: true,
};

export const homeObjTwo = {
  id: "contact",
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Precisa de nossos serviços?",
  headline: "Fale Conosco",
  description:
    "Entre em contato e nos conte mais sobre o que precisa e como o nosso serviço pode o ajudar",
  buttonLabel: "Enviar",
  imgStart: false,
  img: require("../images/svg-2.svg").default,
  alt: "MUDAR ALT AQUI",
  dark: true,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "projects",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Conheça a Bonus",
  headline: "Nossos Projetos",
  description: "Interessado em conhecer nossos projetos?",
  buttonLabel: "Projetos",
  imgStart: false,
  img: require("../images/projectSectionImage.jpg").default,
  alt: "MUDAR ALT AQUI",
  dark: true,
  primary: true,
  darkText: true,
};

export const homeObjFour = {
  id: "testimony",
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Sobre nós",
  headline: "Depoimentos",
  description: "O que nossos parceiros e clientes dizem sobre a Bonus?",
  img: require("../images/svg-3.svg").default,
  alt: "MUDAR ALT AQUI",
  dark: false,
  primary: false,
  darkText: true,
};
