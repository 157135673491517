import React, { useState, useEffect } from "react";
import cookies from "js-cookie";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.css";
import api from "../../services/api";
import {
  TestimonyWrapper,
  CarouselWrapper,
  Slider,
  Testimonial,
  Photo,
  Name,
  Testimony,
  LeftArrow,
  RightArrow,
  PhotoDiv,
} from "./TestimonialCarouselElements";

const TestimonialCarousel = () => {
  const [testimonials, setTestimonials] = useState([]);

  const getTestimonials = () => {
    api
      .get(`api/testimonials`)
      .then((res) => {
        setTestimonials(res.data.testimonials);
      })
      .catch((err) => {}, []);
  };

  useEffect(() => {
    getTestimonials();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const currentLanguageCode = cookies.get("i18next");

  return (
    <Carousel
      itemClass="slider"
      swipeable={true}
      draggable={true}
      showDots={false}
      removeArrowOnDeviceType={["mobile", "tablet"]}
      slidesToSlide={1}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      keyBoardControl={true}
      customTransition="0.6s ease-in-out"
      transitionDuration={600}
      customRightArrow={<RightArrow />}
      customLeftArrow={<LeftArrow />}
      autoPlay={true}
      autoPlaySpeed={6000}
      containerClass="teste carousel-container"
    >
      {testimonials.map((testimonial, index) => {
        return (
          <Slider key={index}>
            <CarouselWrapper>
              <Testimonial key={index}>
                {
                  <TestimonyWrapper>
                    <PhotoDiv>
                      {
                        <Photo
                          src={testimonial.image}
                          alt="Imagem/Logo do depoente"
                          width="640"
                          height="360"
                        />
                      }
                    </PhotoDiv>
                    {currentLanguageCode === "pt" ? (
                      <>
                        <Name>{testimonial.signature}</Name>
                        <Testimony>{testimonial.text}</Testimony>
                      </>
                    ) : (
                      <>
                        <Name>{testimonial.english_signature}</Name>
                        <Testimony>{testimonial.english_text}</Testimony>
                      </>
                    )}
                  </TestimonyWrapper>
                }
              </Testimonial>
            </CarouselWrapper>
          </Slider>
        );
      })}
    </Carousel>
  );
};

export default TestimonialCarousel;
