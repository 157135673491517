import React from "react";
import MobileLanguageButtons from "../MobileLanguageButtons";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
} from "./SidebarAdminElements";

const SidebarAdmin = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="/adminTestimonials" onClick={toggle}>
            Depoimentos
          </SidebarLink>
          <SidebarLink to="/adminProjects" onClick={toggle}>
            Projetos
          </SidebarLink>
          <SidebarLink to="/superAdmin" onClick={toggle}>
            Admin
          </SidebarLink>
          <SidebarLink to="/" onClick={toggle}>
            Sair
          </SidebarLink>
        </SidebarMenu>
        <MobileLanguageButtons />
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default SidebarAdmin;
