import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { AdminWrapper, TextWrapper, Name, DeleteButton } from "./SuperElements";
import { BtnLink, BtnLink2 } from "../ProjectItem/ProjectItemElements";

import { AdminButton } from "./SuperElements";

const SuperAdminItem = ({ email, id, onDelete, is_admin }) => {
  const [token, setToken] = useState();
  const [eAdmin, setEAdmin] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    if(is_admin ==2){
      setEAdmin(true);
    } else{
      setEAdmin(false);
    }
  }, []);


  function remove(id) {
    api
      .delete(`api/users/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        window.location.reload();
        onDelete(id);
      })
      .catch((err) => {}, []);
  }

  function becomeAdmin(id) {
    if (is_admin === 1) {
      api
        .put(`api/users/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
          is_admin: 2,
        })
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {}, []);
    }
    if (is_admin === 2) {
      api
        .put(`api/users/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
          is_admin: 1,
        })
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {}, []);
    }
  }

  return (
    <AdminWrapper>
      <TextWrapper>
        <Name>{email}</Name>
      </TextWrapper>
      <BtnLink2>
        <AdminButton admin={eAdmin}
          onClick={() => {
            if (
              window.confirm(
                "Deseja alterar o status de Administrador desse usuário?"
              )
            )
              becomeAdmin(id);
          }}
        />
      </BtnLink2>
      <BtnLink2>
        <DeleteButton
          onClick={() => {
            if (window.confirm("Tem certeza que quer deletar esse Usuário?"))
              remove(id);
          }}
        />
      </BtnLink2>
    </AdminWrapper>
  );
};

export default SuperAdminItem;
