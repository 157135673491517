import React, { useState } from "react";
import logobonus from "../../images/logo.svg";
import api from "../../services/api";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import {
  LoginContainer,
  LoginWrapper,
  Label,
  Input,
  Button,
  ButtonWrap,
  FormLogin,
  Topline,
  FormGroup,
  FormGroup2,
  FormWrap,
  FormWrapper,
  LoginWrap,
  Image,
} from "./PasswordRecoveryElements";

export default function PasswordRecovery() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      password: newPassword,
      password2: confirmNewPassword,
    };
    try {
      await api.post("api/newPassword", data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      alert("Senha alterada com sucesso!");

      history.push("/adminTestimonials");
    } catch (err) {
      alert("Falha no login, tente novamente.");
    }
  };

  return (
    <>
      <LoginContainer>
        <LoginWrapper>
          <LoginWrap>
            <Image src={logobonus} alt="Logo Bonus Consultoria" />
            <Topline>Área de administrador</Topline>
            <FormLogin onSubmit={handleSubmit}>
              <FormWrapper>
                <FormWrap>
                  <FormGroup>
                    <Label>Nova senha</Label>
                    <Input
                      type="password"
                      name="newPassword"
                      placeholder="Digite sua nova senha"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup2>
                    <Label>Confirme sua senha</Label>
                    <Input
                      type="password"
                      name="confirmNewPassword"
                      placeholder="Digite sua nova senha novamente"
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                  </FormGroup2>
                </FormWrap>
                <ButtonWrap>
                  <Button type="submit">Salvar</Button>
                </ButtonWrap>
              </FormWrapper>
            </FormLogin>
          </LoginWrap>
        </LoginWrapper>
      </LoginContainer>
    </>
  );
}
