import styled from "styled-components";

export const TestimonialsContainer = styled.div`
  color: #fff;
  background: #181c21;
`;

export const TestimonialsWrapper = styled.div`
  display: flex;
  width: 100%;
  /* height: 90vh; */
  max-width: 1250px;
  margin: 0 auto;
  padding: 40px;
  justify-content: space-evenly;

  @media screen and (max-width: 768px) {
    display: contents;
  }
`;

export const TestimonialColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 40px;
  }
`;

export const NewTestimonialColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 40px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
`;

export const Heading = styled.h2`
  margin-bottom: 24px;
  font-weight: 600;
  color: #f7f8fa;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Topline = styled.p`
  color: #97a9d7;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding-top: 50px;
`;
