import axios from "axios";

const route = axios.create({
  // baseURL: "http://localhost:8000/api",
  // baseURL: "https://bonusconsultoria.ejcm.com.br/api",
  // baseURL: "https://sitedoconde.site/api",
  baseURL: "https://bonusconsultoria.com.br/api",
});

export default {
  async contact(form) {
    try {
      const response = await route.post("/send", form);
      return response;
    } catch (error) {
      alert(
        "Ocorreu um erro, não conseguimos nos comunicar com o servidor, tente novamente mais tarde."
      );
    }
  },

  async checkToken() {
    try {
      const response = await route.get("/verify", {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      return response.data;
    } catch (error) {
      alert("Token Inválido.");
    }
  },
};
