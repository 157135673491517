import React from "react";
import { useTranslation } from "react-i18next";
import { animateScroll as scroll } from "react-scroll";
// import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import EJCM from "../../images/ejcm.png";
import logo from "../../images/logo.svg";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  EjcmLogo,
  EjcmDeveloped,
  EjcmWrap,
} from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              <img src={logo} height="50px" alt="Logo Bonus Consultoria" />
            </SocialLogo>
            <WebsiteRights>
              Bonus Consultoria © {new Date().getFullYear()}{" "}
              {t("footer_rights")}
            </WebsiteRights>
            <EjcmWrap>
              <EjcmDeveloped>{t("footer_developed")}</EjcmDeveloped>
              <EjcmLogo
                href="https://ejcm.com.br/"
                target="_blank"
                rel="noopener"
              >
                <img src={EJCM} height="50px" alt="Logo EJCM Consultoria" />
              </EjcmLogo>
            </EjcmWrap>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
