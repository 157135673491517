import React, { useState } from "react";
import logobonus from "../../images/logo.svg";
import api from "../../services/api";
import { useHistory } from "react-router-dom";

import {
  EmailContainer,
  EmailWrapper,
  Label,
  Input,
  Button,
  FormEmail,
  Topline,
  FormGroup,
  FormWrapper,
  ButtonWrap,
  EmailWrap,
  Image,
} from "./EmailRecoveryElements";

export default function EmailRecovery() {
  const [email, setEmail] = useState();

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      email,
    };
    try {
      await api.post("api/passwordRecovery", data);
      alert("Email enviado com sucesso!");

      history.push("/login");
    } catch (err) {
      alert("Falha no envio, tente novamente.");
    }
  };

  return (
    <>
      <EmailContainer>
        <EmailWrapper>
          <EmailWrap>
            <Image src={logobonus} alt="Logo Bonus Consultoria" />
            <Topline>Área de administrador</Topline>
            <FormEmail onSubmit={handleSubmit}>
              <FormWrapper>
                <FormGroup>
                  <Label>Endereço de email</Label>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Digite seu email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
              </FormWrapper>
              <ButtonWrap>
                <Button type="submit">Enviar</Button>
              </ButtonWrap>
            </FormEmail>
          </EmailWrap>
        </EmailWrapper>
      </EmailContainer>
    </>
  );
}
