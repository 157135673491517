import styled from "styled-components";
import { MdDelete } from "react-icons/md";
import { FaStar } from "react-icons/fa";

export const AdminWrapper = styled.div`
  height: auto;
  margin: 10px 2px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 4px;
`;

export const TextWrapper = styled.div`
  margin: 10px 4px;
  width: 85%;
`;

export const Name = styled.p`
  margin: 0;
`;

export const DeleteButton = styled(MdDelete)``;

export const Topline = styled.p`
  color: #97a9d7;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding-top: 50px;
`;

export const AdminButton = styled(FaStar)`

  ${({ admin }) => admin && `
      color: gold;
    `}
`;
