import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { useHistory } from "react-router-dom";

import AdminNavbar from "../../components/AdminNavbar";
import ProjectItem from "../../components/ProjectItem";
import ProjectForm from "../../components/ProjectForm";
import Footer from "../../components/Footer";
import SidebarAdmin from "../../components/SidebarAdmin";

import {
  ProjectsContainer,
  ProjectsWrapper,
  ProjectColumn,
  NewProjectColumn,
  TextWrapper,
  Topline,
} from "./adminProjectsElements";

const AdminProjectsPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const token = localStorage.getItem("token");
  const [projects, setProjects] = useState([]);

   const getProjects = async () => {
    await api
      .get(`api/projects`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setProjects(res.data.projects);
      })
      .catch((err) => {}, []);
  };

  useEffect(() => {
    getProjects();
  }, []);

  const onDeleteProjects = async (id) => {
    await setProjects((projects) => {
      return projects.filter((project) => project.id !== id);
    });
  };

  return (
    <>
      <SidebarAdmin isOpen={isOpen} toggle={toggle} />
      <AdminNavbar toggle={toggle} />
      <ProjectsContainer>
        <ProjectsWrapper>
          <ProjectColumn>
            <TextWrapper>
              <Topline>Projetos Existentes</Topline>
            </TextWrapper>
            {projects.map((project) => {
              return (
                <ProjectItem
                  onDelete={onDeleteProjects}
                  key={project.id}
                  name={project.name}
                  text={project.text}
                  id={project.id}
                />
              );
            })}
          </ProjectColumn>
          <NewProjectColumn>
            <ProjectForm />
          </NewProjectColumn>
        </ProjectsWrapper>
      </ProjectsContainer>
      <Footer />
    </>
  );
};

export default AdminProjectsPage;
