import styled from "styled-components";

export const ConsultingContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#181C21")};
`;

export const ConsultingWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1250px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;

  @media screen and (max-width: 800px) {
    width: 100%;
    height: auto;
  }
`;

export const ConsultingColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  .firstRow {
    margin-top: 60px;
  }

  .lastRow {
    margin-bottom: 60px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  width: 45%;

  .list2 {
    align-self: flex-end;
  }

  @media screen and (max-width: 800px) {
    width: auto;
  }
`;

export const TopLine = styled.p`
  color: #97a9d7;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h2`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#181C21")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin: 0 0 35px 16px;
  font-size: 18px;
  line-height: 32px;
  color: ${({ darkText }) => (darkText ? "#81939E" : "#181C21")};
`;

export const ListLeft = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: circle;
  letter-spacing: 0.1rem;

  @media screen and (max-width: 800px) {
    margin: 0;
  }
`;

export const ListItemLeft = styled.li`
  margin: 0 0 32px 42px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;

  @media screen and (max-width: 800px) {
    margin-left: 16px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const ListRight = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: circle;
  letter-spacing: 0.1rem;
  margin: 0 0 32px 42px;

  .lastItem {
    margin: 0;
  }

  @media screen and (max-width: 800px) {
    margin: 0;

    .lastItem {
      margin: 0;
      margin-left: 16px;
    }
  }
`;

export const ListItemRight = styled.li`
  margin: 0 0 32px 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;

  @media screen and (max-width: 800px) {
    margin-left: 16px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const ImgWrap = styled.div`
  height: 200px;
  width: 45%;

  .leftTriangle {
  }

  .rightTriangle {
    transform: scaleX(-1);
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
