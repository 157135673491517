import styled from "styled-components";

export const AdminContainer = styled.div`
  background: #181c21;
`;

export const AdminWrapper = styled.div`
  display: flex;
  z-index: 1;
  width: 100%;
  max-width: 1250px;
  margin-right: auto;
  margin-left: auto;
  display: block;

  @media screen and (max-width: 768px) {
    padding-left: 0px;
  }
`;

export const Label = styled.label`
  font-size: 15px;
  margin-bottom: 5px;
  color: #81939e;
  display: block;

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

export const Input = styled.input`
  padding: 12px;
  color: black;
  background: #ededed;
  border: none;
  width: 100%;
  resize: none;

  @media screen and (max-width: 480px) {
    width: 100%;
    font-size: 11px;
  }
`;

export const FormGroup = styled.div`
  color: palevioletred;
  width: 300px;
  margin: 18px 0;

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

export const Wrapper = styled.div`
  background-color: yellowgreen;
  height: 900px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Form = styled.form``;

export const Button = styled.button`
  background: ${({ primary }) => (primary ? "#97a9d7" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2 ease-in-out;
  text-decoration: none;
  margin-top: 5px;

  &:hover {
    transition: all 0.2 ease-in-out;
    background: ${({ primary }) => (primary ? "#fff" : "#97a9d7")};
  }

  @media screen and (max-width: 480px) {
    width: 100px;
    height: 50px;
  }
`;

export const Topline = styled.p`
  color: #97a9d7;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding-top: 50px;
`;
