import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ContactService from "../../services/ContactService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import {
  Label,
  Input,
  FormGroup,
  FormWrapper,
  Button,
  Form,
  FormErrors,
} from "./ValidationElements";

const schema = yup.object().shape({
  name: yup
    .string()
    .min(5, "O nome deve conter no mínimo 5 caracteres")
    .required("O nome é obrigatório"),
  email: yup.string().email("Email inválido").required("O email é obrigatório"),
  message: yup
    .string()
    .min(10, "A mensagem deve conter no mínimo 10 caracteres")
    .required("A mensagem não pode ficar em branco"),
});

export default function ValidationForm() {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();

  const onHover = () => {
    setHover(!hover);
  };

  const {
    register: registerForm,
    handleSubmit: handleSubmitForm,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const onSubmitForm = async (data) => {
    const form = {
      name: data.name,
      email: data.email,
      message: data.message,
    };
    toast.current = toast.info("Enviando...", {
      autoClose: true,
      hideProgressBar: true,
    });
    try {
      await ContactService.contact(form).then(res =>{
        console.log(res);
        if(res.data == 'Email enviado com sucesso'){
          toast.success("Enviado com sucesso! Entraremos em contato em breve!", {
            autoClose: true,
          });
          document.getElementById("name").value = '';
          document.getElementById("email").value = '';
          document.getElementById("message").value = '';
          window.scrollTo(0, 0);
        }
      });
    } catch (err) {}
  };

  return (
    <Form onSubmit={handleSubmitForm(onSubmitForm)}>
      <FormWrapper>
        <FormGroup>
          <Label>{t("contact_form_name")}</Label>
          <Input
            id='name'
            rows="1"
            type="text"
            name="name"
            placeholder={t("contact_form_name_placeholder")}
            {...registerForm("name")}
          />
          <FormErrors>
            {errors.name?.message && <p>{errors.name?.message}</p>}
          </FormErrors>
        </FormGroup>
        <FormGroup>
          <Label>{t("contact_form_email")}</Label>
          <Input
            id="email"
            rows="1"
            type="text"
            name="email"
            placeholder={t("contact_form_email_placeholder")}
            {...registerForm("email")}
          />
          <FormErrors>
            {errors.email?.message && <p>{errors.email?.message}</p>}
          </FormErrors>
        </FormGroup>
        <FormGroup>
          <Label>{t("contact_form_message")}</Label>
          <Input
            id="message"
            rows="5"
            type="text"
            name="message"
            placeholder={t("contact_form_message_placeholder")}
            {...registerForm("message")}
          />
          <FormErrors>
            {errors.message?.message && <p>{errors.message?.message}</p>}
          </FormErrors>
        </FormGroup>
      </FormWrapper>
      <div>
        <ToastContainer />
        <Button
          type="submit"
          onMouseEnter={onHover}
          onMouseLeave={onHover}
          primary="true"
          dark={true}
          duration={500}
          spy={true}
        >
          {t("contact_form_submit_button")}
        </Button>
      </div>
    </Form>
  );
}
