import styled from "styled-components";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

export const TestimonialWrapper = styled.div`
  width: 75%;
  height: auto;
  margin: 10px 2px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 4px;
`;

export const TextWrapper = styled.div`
  margin: 10px 4px;
  width: 85%;
`;

export const Name = styled.p`
  margin: 0;
`;

export const EditButton = styled(FaEdit)``;

export const DeleteButton = styled(MdDelete)``;
