import styled from "styled-components";

export const EditContainer = styled.div`
  color: #fff;
  background: #181c21;
`;

export const EditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 40px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding: 40px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
`;

export const Form = styled.form``;

export const FormGroup = styled.div`
  width: 200px;
  margin-top: 5%;
  margin-bottom: 5%;

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

export const Topline = styled.p`
  color: #97a9d7;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding-top: 50px;
`;
