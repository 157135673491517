import React from "react";
import { useTranslation } from "react-i18next";
import MobileLanguageButtons from "../MobileLanguageButtons";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  const { t } = useTranslation();

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="consulting" onClick={toggle}>
            {t("sidebar_consulting")}
          </SidebarLink>
          <SidebarLink to="contact" onClick={toggle}>
            {t("sidebar_contact")}
          </SidebarLink>
          <SidebarLink to="projects" onClick={toggle}>
            {t("sidebar_projects")}
          </SidebarLink>
          <SidebarLink to="testimony" onClick={toggle}>
            {t("sidebar_testimonial")}
          </SidebarLink>
        </SidebarMenu>
        <MobileLanguageButtons />
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
