import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  .btn-link {
    color: #fff;
  }
`;

export const Container = styled.div`
  display: flex;

  .min-width0 {
    min-width: 0;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const LanguageList = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LanguageText = styled.p`
  text-transform: uppercase;
`;
