import React, { useState, useEffect } from "react";
import cookies from "js-cookie";
import api from "../../services/api";
import { useTranslation } from "react-i18next";
import {
  ProjectContainer,
  ProjectWrapper,
  ProjectRow,
  Column1,
  Column2,
  TextWrapper,
  Heading,
  Subtitle,
  MidiaWrap,
  Img,
  Iframe,
} from "./ProjectsElements";

const projectSettings = [
  {
    imageStart: false,
    lightBg: false,
    lightText: true,
    darkText: true,
  },
];

const Projects = () => {
  const [projects, setProjects] = useState([]);

  const getProjects = () => {
    api
      .get(`api/projects`)
      .then((res) => {
        setProjects(res.data.projects);
      })
      .catch((err) => {}, []);
  };

  useEffect(() => {
    getProjects();
  }, []);

  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next");

  return (
    <>
      <li style={{ listStyleType: "none" }}>
        {projects.map(
          (project, index, { imageStart, lightBg, darkText, lightText }) => (
            <div key={index}>
              <ProjectContainer lightBg={index % 2 === 0 ? lightBg : !lightBg}>
                {
                  <ProjectWrapper>
                    <ProjectRow
                      imgStart={index % 2 === 0 ? imageStart : !imageStart}
                    >
                      <Column1>
                        <TextWrapper>
                          {currentLanguageCode === "pt" ? (
                            <>
                              <Heading lightText={!lightText}>
                                {project.name}
                              </Heading>
                              <Subtitle darkText={!darkText}>
                                {project.text}
                              </Subtitle>
                            </>
                          ) : (
                            <>
                              <Heading lightText={!lightText}>
                                {project.english_name}
                              </Heading>
                              <Subtitle darkText={!darkText}>
                                {project.english_text}
                              </Subtitle>
                            </>
                          )}
                        </TextWrapper>
                      </Column1>
                      <Column2>
                        {project.image === null ? (
                          <MidiaWrap>
                            <Iframe
                              src={project.video}
                              width="560"
                              height="315"
                              frameborder="0"
                              allow="autoplay; encrypted-media"
                              allowfullscreen
                              title="video"
                            />
                          </MidiaWrap>
                        ) : project.video == null ? (
                          <MidiaWrap>
                            <Img src={project.image} alt="Imagem do projeto" />
                          </MidiaWrap>
                        ) : (
                          <Iframe
                            src={project.video}
                            width="560"
                            height="315"
                            frameborder="0"
                            allow="autoplay; encrypted-media"
                            allowfullscreen
                            title="video"
                          />
                        )}
                      </Column2>
                    </ProjectRow>
                  </ProjectWrapper>
                }
              </ProjectContainer>
            </div>
          )
        )}
      </li>
    </>
  );
};

export default Projects;
