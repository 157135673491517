import React from "react";
import { useTranslation } from "react-i18next";
import {
  ListLeft,
  ListRight,
  ListItemLeft,
  ListItemRight,
  ImgWrap,
  Img,
} from "./ConsultingElements";
import {
  Container,
  Wrapper,
  Column,
  Row,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
} from "../GeneralElements/GeneralElements";
import Triangulo11 from "../../images/triangulos1.svg";
import Triangulo12 from "../../images/triangulos2.svg";


const ConsultingSection = ({
  lightBg,
  id,
  imgStart,
  lightText,
  darkText,
  triangle1,
  alt,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Container lightBg={lightBg} id={id}>
        <Wrapper>
          <Column imgStart={imgStart}>
            <Row className="firstRow">
              <TextWrapper>
                <TopLine>{t("consultancy_topline")}</TopLine>
                <Heading lightText={lightText}>
                  {t("consultancy_headline")}
                </Heading>
                <Subtitle darkText={darkText}>
                  {t("consultancy_description")}
                </Subtitle>
                <ListLeft>
                  <ListItemLeft>{t("consultancy_left_list_1")}</ListItemLeft>
                  <ListItemLeft>{t("consultancy_left_list_2")}</ListItemLeft>
                </ListLeft>
              </TextWrapper>
              <ImgWrap>
                <Img className="rightTriangle" src={Triangulo11} alt={alt}/>
              </ImgWrap>
            </Row>
            <Row className="lastRow">
              <ImgWrap>
                <Img className="leftTriangle" src={Triangulo12} alt={alt} />
              </ImgWrap>
              <TextWrapper>
                <ListRight>
                  <ListItemRight>{t("consultancy_right_list_1")}</ListItemRight>
                  <ListItemRight>{t("consultancy_right_list_2")}</ListItemRight>
                  <ListItemRight className="lastItem">
                    {t("consultancy_right_list_3")}
                  </ListItemRight>
                </ListRight>
              </TextWrapper>
            </Row>
          </Column>
        </Wrapper>
      </Container>
    </>
  );
};

export default ConsultingSection;
