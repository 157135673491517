import React, { useEffect } from "react";
import i18next from "i18next";
import cookies from "js-cookie";
import { Container, Button } from "./MobileLangButtonsElements";

import "bootstrap/dist/css/bootstrap.min.css";
import "flag-icon-css/css/flag-icon.min.css";
import "bootstrap/dist/js/bootstrap.js";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "pt",
    name: "Português",
    country_code: "br",
  },
];

const MobileLanguageButtons = () => {
  const currentLanguageCode = cookies.get("i18next") || "pt";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage]);

  return (
    <Container>
      <div>
        <div>
          {languages.map(({ code, name, country_code }) => (
            <li key={country_code}>
              <Button
                className="dropdown-item"
                onClick={() => i18next.changeLanguage(code)}
                // disabled={code === currentLanguageCode}
              >
                <span
                  className={`flag-icon flag-icon-${country_code} mx-2`}
                  // style={{ opacity: code === currentLanguageCode ? 0.5 : 1 }}
                />
                {name}
              </Button>
            </li>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default MobileLanguageButtons;
