import React, { useState, useEffect } from "react";
import api from "../../services/api";
import {
  ProjectWrapper,
  TextWrapper,
  Name,
  EditButton,
  DeleteButton,
  BtnLink,
} from "./ProjectItemElements";

const ProjectItem = ({ name, id, onDelete }) => {
  const [token, setToken] = useState();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  function remove(id) {
    api
      .delete(`api/projects/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        onDelete(id);
      })
      .catch((err) => {}, []);
  }

  const postId = (id) => {
    localStorage.setItem("id", id);
  };

  return (
    <ProjectWrapper>
      <TextWrapper>
        <Name>{name}</Name>
      </TextWrapper>
      <BtnLink to="/editProject">
        <EditButton onClick={() => postId(id)} />
      </BtnLink>
      <BtnLink>
        <DeleteButton
          onClick={() => {
            if (window.confirm("Tem certeza que quer deletar esse Projeto?"))
              remove(id);
          }}
        />
      </BtnLink>
    </ProjectWrapper>
  );
};

export default ProjectItem;
