import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages";
import ProjectsPage from "./pages/projects";
import AdminTestimonialsPage from "./pages/adminTestimonials/";
import adminProjectsPage from "./pages/adminProjects";
import Login from "./pages/login";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import Service from "../src/services/ContactService";
import PasswordRecovery from "./components/PasswordRecovery";
import SuperAdminPage from "./pages/superAdmin.jsx";
import EmailRecovery from "./components/EmailRecovery";
import AdminEditProject from "./pages/adminEditProject";
import AdminEditTestimonial from "./pages/adminEditTestimonial";

var validToken = 0;

const requireAdmin = async (to, from, next) => {
  if (to.meta.auth) {
    if (localStorage.getItem("token")) {
      validToken = await Service.checkToken();
      if (validToken === true) {
        next();
      }
    }

    next.redirect("/");
  }
};

function App() {
  return (
    <Router>
      <GuardProvider>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/projects" component={ProjectsPage} exact />
          <GuardedRoute
            path="/adminTestimonials"
            component={AdminTestimonialsPage}
            guards={[requireAdmin]}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/adminProjects"
            component={adminProjectsPage}
            guards={[requireAdmin]}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/superAdmin"
            component={SuperAdminPage}
            guards={[requireAdmin]}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/editProject"
            component={AdminEditProject}
            guards={[requireAdmin]}
            meta={{ auth: true }}
          />
          <GuardedRoute
            path="/editTestimonial"
            component={AdminEditTestimonial}
            guards={[requireAdmin]}
            meta={{ auth: true }}
          />
          <Route path="/login" component={Login} exact />
          <Route path="/emailRecovery" component={EmailRecovery} exact />
          <Route path="/passwordRecovery" component={PasswordRecovery} exact />
          <Route path='*'>
            <Redirect to='/'/>
          </Route>
        </Switch>
      </GuardProvider>
    </Router>
  );
}

export default App;
