import React, { useEffect } from "react";
import i18next from "i18next";
import cookies from "js-cookie";
import { Container, Button } from "./LanguageButtonsElements";

import "bootstrap/dist/css/bootstrap.min.css";
import "flag-icon-css/css/flag-icon.min.css";
import "bootstrap/dist/js/bootstrap.js";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "pt",
    name: "Português",
    country_code: "br",
  },
];

const LanguageButtons = () => {
  const currentLanguageCode = cookies.get("i18next") || "pt";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage]);

  return (
    <Container>
      <div className="d-flex justify-content-end align-items-center">
        <div className="dropdown">
          <Button
            className="btn btn-outline-light dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {currentLanguageCode === "pt" ? <>pt</> : <>en</>}
          </Button>
          <ul
            className="dropdown-menu min-width0"
            aria-labelledby="dropdownMenuButton1"
          >
            {languages.map(({ code, name, country_code }) => (
              <li key={country_code}>
                <button
                  className="dropdown-item"
                  onClick={() => i18next.changeLanguage(code)}
                  disabled={code === currentLanguageCode}
                >
                  <span
                    className={`flag-icon flag-icon-${country_code} mx-2`}
                    style={{ opacity: code === currentLanguageCode ? 0.5 : 1 }}
                  />
                  {code}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default LanguageButtons;
