import React, { useState } from "react";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import {
  Label,
  Input,
  File,
  FormGroup,
  FormWrapper,
  Button,
  Form,
  Topline,
  AdminContainer,
  AdminWrapper,
} from "./TestimonialFormElements";

const TestimonialForm = () => {
  const [data, setData] = useState({
    signature: "",
    english_signature: "",
    text: "",
    english_text: "",
    photo: "",
  });
  const history = useHistory();
  const token = localStorage.getItem("token");

  const [baseImage, setBaseImage] = useState("");

  async function submit(e) {
    e.preventDefault();
    await api
      .post(
        `api/testimonials`,
        {
          signature: data.signature,
          english_signature: data.english_signature,
          text: data.text,
          english_text: data.english_text,
          photo: baseImage.split(",")[1],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        alert(
          "Você precisa adicionar ao menos uma imagem para criar o depoimento."
        );
      });
  }

  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <AdminContainer>
      <AdminWrapper>
        <Form onSubmit={(e) => submit(e)}>
          <FormWrapper>
            <Topline>Adicionar novo Depoimento</Topline>
            <FormGroup>
              <Label>Nome</Label>
              <Input
                rows="1"
                type="text"
                name="signature"
                onChange={(e) => handle(e)}
                id="signature"
                value={data.signature}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Nome (Inglês)</Label>
              <Input
                rows="1"
                type="text"
                name="english_signature"
                onChange={(e) => handle(e)}
                id="english_signature"
                value={data.english_signature}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Depoimento</Label>
              <Input
                rows="5"
                type="text"
                name="text"
                onChange={(e) => handle(e)}
                id="text"
                value={data.text}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Depoimento (Inglês)</Label>
              <Input
                rows="5"
                type="text"
                name="english_text"
                onChange={(e) => handle(e)}
                id="english_text"
                value={data.english_text}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Imagem do Depoimento</Label>
              <File
                type="file"
                name="photo"
                id="photo"
                accept=".jpeg, .png, .jpg"
                onChange={(e) => {
                  uploadImage(e);
                  handle(e);
                }}
                value={data.photo}
              />
            </FormGroup>
          </FormWrapper>
          <div>
            {baseImage === "" ? (
              <></>
            ) : (
              <>
                <img
                  src={baseImage}
                  height="200px"
                  alt="Imagem selecionada para upload"
                />
              </>
            )}
          </div>
          <Button
            type="submit"
            // onMouseEnter={onHover}
            // onMouseLeave={onHover}
            primary="true"
            dark={true}
            duration={500}
            spy={true}
          >
            Criar
          </Button>
        </Form>
      </AdminWrapper>
    </AdminContainer>
  );
};

export default TestimonialForm;
