import React from "react";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt, FaRegEnvelope } from "react-icons/fa";
import ValidationForm from "../../components/ValidationForm";

import {
  BtnWrap,
  FormaContato,
  Contatos,
  Contato,
  WrapperContato,
  SectionTitle,
} from "./ContactElements";

import {
  Container,
  Wrapper,
  Column,
  Row,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
} from "../GeneralElements/GeneralElements";

const ContactSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Container lightBg={lightBg} id={id}>
        <Wrapper>
          <Column>
            <Row className="contactRow">
              <TextWrapper>
                <TopLine>{t("contact_topline")}</TopLine>
                <Heading lightText={lightText}>{t("contact_headline")}</Heading>
                <Subtitle darkText={darkText}>
                  {t("contact_description")}
                </Subtitle>
                <BtnWrap>
                  <ValidationForm />
                </BtnWrap>
              </TextWrapper>
              <FormaContato>
                <SectionTitle>{t("contact_company_name")}</SectionTitle>
                <Contatos>
                  <WrapperContato>
                    <FaPhoneAlt />
                    <Contato>{t("contact_company_phone1")}</Contato>
                  </WrapperContato>
                  <WrapperContato>
                    <FaPhoneAlt />
                    <Contato>{t("contact_company_phone2")}</Contato>
                  </WrapperContato>
                  <WrapperContato>
                    <FaRegEnvelope />
                    <Contato>{t("contact_company_email")}</Contato>
                  </WrapperContato>
                </Contatos>
              </FormaContato>
            </Row>
          </Column>
        </Wrapper>
      </Container>
    </>
  );
};

export default ContactSection;
