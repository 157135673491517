import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:8000/'
  // baseURL: 'https://bonusconsultoria.ejcm.com.br/'
  // baseURL: "https://sitedoconde.site/",
  baseURL: "https://bonusconsultoria.com.br/",
})

export default api;
