import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import logo from "../../images/logo.svg";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  Img,
  MobileIcon,
  NavMenu,
  NavBtn,
  NavBtnLink,
  HamburguerIcon,
} from "./AdminNavbarElements";

const AdminNavbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [isAdmin, setIsAdmin] = useState([]);

  const token = localStorage.getItem("token");
  const userEmail = localStorage.getItem("email");

  const getIsAdmin = async () => {
    try{
      await api
        .get(`api/isAdmin`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            email: userEmail,
          },
        })
        .then((res) => {
          setIsAdmin(res.data.isAdmin);
        })
      } catch(err){};
  };

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  useEffect(() => {
    getIsAdmin();
  }, [window.location.href]);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#000000" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              <Img src={logo} alt="Logo Bonus Consultoria" />
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <HamburguerIcon />
            </MobileIcon>
            <NavMenu>
              <NavBtn>
                <NavBtnLink to="/adminTestimonials">Depoimentos</NavBtnLink>
              </NavBtn>
              <NavBtn>
                <NavBtnLink to="/adminProjects">Projetos</NavBtnLink>
              </NavBtn>
              {isAdmin !== 2 ? (
                <></>
              ) : (
                <NavBtn>
                  <NavBtnLink to="/superAdmin">Usuários</NavBtnLink>
                </NavBtn>
              )}
              <NavBtn>
                <NavBtnLink to="/">Sair</NavBtnLink>
              </NavBtn>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default AdminNavbar;
