import React from "react";
import { useTranslation } from "react-i18next";
import { BtnWrap, ImgWrap, Img, NavBtnLink } from "./ProjectElements";

import {
  Container,
  Wrapper,
  Column,
  Row,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  // Column1,
  // Column2
} from "../GeneralElements/GeneralElements";

const ProjectSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Container lightBg={lightBg} id={id}>
        <Wrapper>
          <Column className="projects">
            <Row>
              <TextWrapper>
                <TopLine>{t("projects_topline")}</TopLine>
                <Heading lightText={lightText}>
                  {t("projects_headline")}
                </Heading>
                <Subtitle darkText={darkText}>
                  {t("projects_description")}
                </Subtitle>
                <BtnWrap>
                  <NavBtnLink to="/projects">
                    {t("projects_redirect_button")}
                  </NavBtnLink>
                </BtnWrap>
              </TextWrapper>
            </Row>
            <Row className="secondRow">
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Row>
          </Column>
        </Wrapper>
      </Container>
    </>
  );
};

export default ProjectSection;
