import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { useHistory } from "react-router-dom";

import AdminNavbar from "../../components/AdminNavbar";
import Footer from "../../components/Footer";
import SidebarAdmin from "../../components/SidebarAdmin";

import {
  EditContainer,
  EditWrapper,
  Column,
  TextWrapper,
  Topline,
} from "./adminEditElements";

import {
  Label,
  Input,
  File,
  FormGroup,
  FormWrapper,
  Button,
  Form,
} from "../../components/TestimonialForm/TestimonialFormElements";

const AdminEditProject = () => {
  const id = localStorage.getItem("id");
  const project_id = id;

  const [isOpen, setIsOpen] = useState(false);
  const [baseImage, setBaseImage] = useState("");
  const [data, setData] = useState({
    name: "",
    english_name: "",
    text: "",
    english_text: "",
    photo: "",
    video: "",
    user_id: "",
  });

  const token = localStorage.getItem("token");

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  async function submit(e) {
    if (data.video) {
      var urlTratada = data.video.replace("watch?v=", "embed/");
    }

    e.preventDefault();
    await api
      .put(
        `api/projects/${id}`,
        {
          name: data.name,
          english_name: data.english_name,
          text: data.text,
          english_text: data.english_text,
          video: urlTratada,
          photo: baseImage.split(",")[1],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        window.location = "/adminProjects";
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    api.get(`api/projects/${id}`).then((res) => {
      setData(res.data.project);
    });
  }, []);

  // useEffect(() => {
  //   document.getElementById("name").value = data.name;
  //   document.getElementById("english_name").value = data.english_name;
  //   document.getElementById("text").value = data.text;
  //   document.getElementById("english_text").value = data.english_text;
  //   document.getElementById("video").value = data.video;
  // }, []);

  return (
    <>
      <SidebarAdmin isOpen={isOpen} toggle={toggle} />
      <AdminNavbar toggle={toggle} />
      <EditContainer>
        <EditWrapper>
          <Column>
            <TextWrapper>
              <Topline>Editar Projeto</Topline>
            </TextWrapper>

            <Form onSubmit={(e) => submit(e)}>
              <FormWrapper>
                <FormGroup>
                  <Label>Nome do Projeto</Label>
                  <Input
                    className="edit"
                    rows="1"
                    type="text"
                    name="name"
                    onChange={(e) => handle(e)}
                    id="name"
                    value={data.name}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Nome do Projeto (Inglês)</Label>
                  <Input
                    className="edit"
                    rows="1"
                    type="text"
                    name="english_name"
                    onChange={(e) => handle(e)}
                    id="english_name"
                    value={data.english_name}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Texto Projeto</Label>
                  <Input
                    className="edit text"
                    rows="2"
                    type="text"
                    name="text"
                    onChange={(e) => handle(e)}
                    id="text"
                    value={data.text}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Texto Projeto (Inglês)</Label>
                  <Input
                    className="edit text"
                    rows="2"
                    type="text"
                    name="english_text"
                    onChange={(e) => handle(e)}
                    id="english_text"
                    value={data.english_text}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Link do Video</Label>
                  <Input
                    className="edit"
                    rows="1"
                    type="text"
                    name="video"
                    onChange={(e) => handle(e)}
                    id="video"
                    value={data.video}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Imagem do Projeto</Label>
                  <File
                    type="file"
                    name="photo"
                    id="photo"
                    accept=".jpeg, .png, .jpg"
                    onChange={(e) => {
                      uploadImage(e);
                      handle(e);
                    }}
                    value={data.photo}
                  />
                </FormGroup>
              </FormWrapper>
              <div>
                {baseImage === "" ? (
                  <></>
                ) : (
                  <>
                    <img
                      src={baseImage}
                      height="200px"
                      alt="Imagem selecionada para upload"
                    />
                  </>
                )}
              </div>
              <Button
                type="submit"
                primary="true"
                dark={true}
                duration={500}
                spy={true}
              >
                Atualizar Projeto
              </Button>
            </Form>
          </Column>
        </EditWrapper>
      </EditContainer>
      <Footer />
    </>
  );
};

export default AdminEditProject;
