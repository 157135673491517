import React, { useState, useEffect } from "react";
import api from "../../services/api";

import AdminNavbar from "../../components/AdminNavbar";
import SuperAdminExists from "../../components/SuperAdminExists";
import SuperAdminForm from "../../components/SuperAdminForm";
import Footer from "../../components/Footer";
import SidebarAdmin from "../../components/SidebarAdmin";

import {
  AdminContainer,
  AdminWrapper,
  AdminColumn,
  NewAdminColumn,
  TextWrapper,
  Topline,
} from "./superAdminElements";

const SuperAdminPage = () => {
  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    try{
      await api
        .get(`api/users`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setUsers(res.data.users);
        })
      } catch(err){
        alert("Falha, tente novamente!");
      };
  };

  useEffect(() => {
    getUsers();
  }, []);

  const onDeleteUser = (id) => {
    setUsers((users) => {
      return users.filter((user) => user.id !== id);
    });
  };

  return (
    <>
      <SidebarAdmin isOpen={isOpen} toggle={toggle} />
      <AdminNavbar toggle={toggle} />
      <AdminContainer>
        <AdminWrapper>
          <AdminColumn>
            <TextWrapper>
              <Topline>Usuários existentes</Topline>
            </TextWrapper>
            {users.map((user) => {
              return (
                <SuperAdminExists
                  onDelete={onDeleteUser}
                  key={user.id}
                  id={user.id}
                  email={user.email}
                  is_admin={user.is_admin}
                />
              );
            })}
          </AdminColumn>
          <NewAdminColumn>
            <SuperAdminForm />
          </NewAdminColumn>
        </AdminWrapper>
      </AdminContainer>
      <Footer />
    </>
  );
};

export default SuperAdminPage;
