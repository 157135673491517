import React, { useState, useEffect } from "react";
import api from "../../services/api";

import AdminNavbar from "../../components/AdminNavbar";
import TestimonialItem from "../../components/TestimonialItem";
import TestimonialForm from "../../components/TestimonialForm";
import Footer from "../../components/Footer";
import SidebarAdmin from "../../components/SidebarAdmin";

import {
  TestimonialsContainer,
  TestimonialsWrapper,
  TestimonialColumn,
  NewTestimonialColumn,
  TextWrapper,
  Topline,
} from "./adminTestimonialsElements";

const AdminTestimonialsPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const token = localStorage.getItem("token");
  const [testimonials, setTestimonials] = useState([]);

  const getTestimonials = async () => {
    try{
      await api
        .get(`api/testimonials`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setTestimonials(res.data.testimonials);
        })
    } catch(err){};
  };

  useEffect(() => {
    getTestimonials();
  }, []);

  const onDeleteTestimonials = (id) => {
    setTestimonials((testimonials) => {
      return testimonials.filter((testimonials) => testimonials.id !== id);
    });
  };

  return (
    <>
      <SidebarAdmin isOpen={isOpen} toggle={toggle} />
      <AdminNavbar toggle={toggle} />
      <TestimonialsContainer>
        <TestimonialsWrapper>
          <TestimonialColumn>
            <TextWrapper>
              <Topline>Depoimentos Existentes</Topline>
            </TextWrapper>
            {testimonials.map((testimonial) => {
              return (
                <TestimonialItem
                  onDelete={onDeleteTestimonials}
                  key={testimonial.id}
                  signature={testimonial.signature}
                  text={testimonial.text}
                  id={testimonial.id}
                />
              );
            })}
          </TestimonialColumn>
          <NewTestimonialColumn>
            <TestimonialForm />
          </NewTestimonialColumn>
        </TestimonialsWrapper>
      </TestimonialsContainer>
      <Footer />
    </>
  );
};

export default AdminTestimonialsPage;
