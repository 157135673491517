import React, { useState } from "react";
import api from "../../services/api";
import { useHistory } from "react-router-dom";

import {
  Label,
  Input,
  File,
  FormGroup,
  FormWrapper,
  Button,
  Form,
  Topline,
} from "../TestimonialForm/TestimonialFormElements";

const ProjectForm = () => {
  const [data, setData] = useState({
    name: "",
    english_name: "",
    text: "",
    english_text: "",
    photo: "",
    video: "",
  });

  const token = localStorage.getItem("token");

  const [baseImage, setBaseImage] = useState("");

  async function submit(e) {
    var urlTratada = data.video.replace("watch?v=", "embed/");
    e.preventDefault();
    await api
      .post(
        `api/projects`,
        {
          name: data.name,
          english_name: data.english_name,
          text: data.text,
          english_text: data.english_text,
          photo: baseImage.split(",")[1],
          video: urlTratada,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        alert(
          "Você precisa adicionar ao menos uma imagem ou vídeo para criar o projeto."
        );
      });
  }

  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <Form onSubmit={(e) => submit(e)}>
      <FormWrapper>
        <Topline>Adicionar novo Projeto</Topline>
        <FormGroup>
          <Label>Nome do Projeto</Label>
          <Input
            rows="1"
            type="text"
            name="name"
            onChange={(e) => handle(e)}
            id="name"
            value={data.name}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Nome do Projeto (Inglês)</Label>
          <Input
            rows="1"
            type="text"
            name="english_name"
            onChange={(e) => handle(e)}
            id="english_name"
            value={data.english_name}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Texto Projeto</Label>
          <Input
            rows="5"
            type="text"
            name="text"
            onChange={(e) => handle(e)}
            id="text"
            value={data.text}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Texto Projeto (Inglês)</Label>
          <Input
            rows="5"
            type="text"
            name="english_text"
            onChange={(e) => handle(e)}
            id="english_text"
            value={data.english_text}
          />
        </FormGroup>
        <FormGroup>
          <Label>Link do Video</Label>
          <Input
            rows="1"
            type="text"
            name="video"
            onChange={(e) => handle(e)}
            id="video"
            value={data.video}
          />
        </FormGroup>
        <FormGroup>
          <Label>Imagem do Projeto</Label>
          <File
            type="file"
            name="photo"
            id="photo"
            accept=".jpeg, .png, .jpg"
            onChange={(e) => {
              uploadImage(e);
              handle(e);
            }}
            value={data.photo}
          />
        </FormGroup>
      </FormWrapper>
      <div>
        {baseImage === "" ? (
          <></>
        ) : (
          <>
            <img
              src={baseImage}
              height="200px"
              alt="Imagem selecionada para upload"
            />
          </>
        )}
      </div>
      <Button
        type="submit"
        primary="true"
        dark={true}
        duration={500}
        spy={true}
      >
        Criar
      </Button>
    </Form>
  );
};

export default ProjectForm;
